import Component from '../classes/Component';
import { $dom } from '../helpers/dom';
import { $events } from '../helpers/events';

const {get, attr} = $dom;

const headerLinkSelector = '.js-header-link';

export function headerScroll() {

  const scrollToSection = event => {
    event.preventDefault();

    const link = event.target.closest(headerLinkSelector);

    const anchor = attr(link, 'href');

    scrollTo({
      top: get(anchor).offsetTop - 100,
      behavior: 'smooth',
    })
  }

  return new Component({
    name: 'headerScroll',
    requiredTargets: headerLinkSelector,
    onCreate() { },
    onInit() {
      $events.delegate
        .on('click', headerLinkSelector, scrollToSection)
     },
    onDestroy() {
      $events.delegate
        .off('click', headerLinkSelector, scrollToSection)
     }
  })
}